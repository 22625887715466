var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "header" }, [
          _c(
            "h1",
            { staticClass: "title" },
            [
              _c("Logo", { staticClass: "logo" }),
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ],
            1
          ),
        ]),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }