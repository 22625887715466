var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("i", {
    class: [
      "icon",
      `${_vm.iconsGroup}`,
      `icon-${_vm.icon}`,
      `${_vm.icon}`,
      `icon${_vm.size}`,
    ],
    on: { click: _vm.handleClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }