var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card editor" },
    [
      _c("div", { staticClass: "card-body" }, [
        _c(
          "form",
          { staticClass: "form formArea", attrs: { autocomplete: "off" } },
          [
            _c(
              "div",
              { staticClass: "main" },
              [
                _c("div", { staticClass: "title" }, [
                  _c(
                    "div",
                    { staticClass: "alias" },
                    [
                      _vm.isAliasEditable
                        ? _c("BaseInput", {
                            staticClass: "aliasEditable",
                            attrs: {
                              placeholder: `${_vm.$t("enter")} ${_vm.$tc(
                                "alias"
                              )}`,
                            },
                            model: {
                              value: _vm.item.alias,
                              callback: function ($$v) {
                                _vm.$set(_vm.item, "alias", $$v)
                              },
                              expression: "item.alias",
                            },
                          })
                        : _c("h6", { staticClass: "aliasSubtitle" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$tc("alias")) +
                                ": " +
                                _vm._s(_vm.item.alias) +
                                " "
                            ),
                          ]),
                      _c(
                        "BaseButton",
                        {
                          staticClass: "aliasButton",
                          attrs: { size: "sm" },
                          on: {
                            click: function ($event) {
                              _vm.isAliasEditable = !_vm.isAliasEditable
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isAliasEditable
                                  ? _vm.$t("close")
                                  : `${_vm.$t("edit")} ${_vm.$tc("alias")}`
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.$slots.title
                    ? _c("h1", { staticClass: "title" }, [_vm._t("title")], 2)
                    : _vm._e(),
                  _c("h1", { staticClass: "title" }, [
                    _vm.item && "name" in _vm.item
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.name,
                              expression: "item.name",
                            },
                          ],
                          attrs: {
                            placeholder: `${_vm.$t("enter")} ${_vm.$t(
                              "title"
                            )} ${_vm.$t("here")}...`,
                          },
                          domProps: { value: _vm.item.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.item, "name", $event.target.value)
                            },
                          },
                        })
                      : _vm._e(),
                  ]),
                ]),
                _c("div", { staticClass: "subtitle" }, [_vm._t("subtitle")], 2),
                _vm.supportsEditor
                  ? _c(
                      "BaseInput",
                      [
                        _c("TextEditor", {
                          model: {
                            value: _vm.item.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "description", $$v)
                            },
                            expression: "item.description",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._t("middle"),
                _vm._t("bottom"),
                ["post", "console", "accessory", "page"].includes(_vm.item.type)
                  ? _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: { label: "Focus Keyword" },
                          model: {
                            value: _vm.focusKeyword,
                            callback: function ($$v) {
                              _vm.focusKeyword = $$v
                            },
                            expression: "focusKeyword",
                          },
                        }),
                        _c(
                          "Tabs",
                          [
                            _c(
                              "Tab",
                              { attrs: { title: "SEO" } },
                              [
                                _c("SeoAssessor", {
                                  attrs: {
                                    keyword: _vm.focusKeyword,
                                    title: _vm.item.name,
                                    "title-width": _vm.item.name.length,
                                    description: _vm.item.description_socials,
                                    text: _vm.displayedPhoto
                                      ? `${_vm.item.description}<img src='
                ${_vm.displayedPhoto.photo.large.URL}' alt='${_vm.displayedPhoto.photo.alt}'>`
                                      : _vm.item.description,
                                    locale: "en_US",
                                    permalink: `https://gamemedium.com/${
                                      _vm.webappRoutes[_vm.item.type]
                                    }/${_vm.item.alias}`,
                                    url: `${_vm.webappRoutes[_vm.item.type]}/${
                                      _vm.item.alias
                                    }`,
                                    "result-filter": _vm.assessorResultFilter,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "Tab",
                              { attrs: { title: "Content" } },
                              [
                                _c("ContentAssessor", {
                                  attrs: {
                                    title: _vm.item.name,
                                    "title-width": _vm.item.name.length,
                                    description: _vm.item.description_socials,
                                    url: `/${_vm.webappRoutes[_vm.item.type]}/${
                                      _vm.item.alias
                                    }`,
                                    text: _vm.item.description,
                                    locale: "en_US",
                                    "result-filter": _vm.assessorResultFilter,
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "Tab",
                              { attrs: { title: "Snippet Preview" } },
                              [
                                _c("SnippetPreview", {
                                  staticStyle: {
                                    background: "white",
                                    padding: "15px",
                                    "border-radius": "6px",
                                  },
                                  attrs: {
                                    title: _vm.item.name,
                                    description: _vm.item.description_socials,
                                    url: `/${_vm.webappRoutes[_vm.item.type]}/${
                                      _vm.item.alias
                                    }`,
                                    "base-url": "https://gamemedium.com",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { staticClass: "side" }, [
              _c(
                "div",
                { staticClass: "sideInner" },
                [
                  _vm._t("sidebar-top"),
                  _c(
                    "BaseButton",
                    {
                      staticClass: "saveButton",
                      attrs: {
                        slot: "footer",
                        type: "green",
                        gradient: "",
                        fill: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("submit")
                        },
                      },
                      slot: "footer",
                    },
                    [_vm._v(" " + _vm._s(_vm.submitButtonText) + " ")]
                  ),
                  _vm.item && _vm.item.hasOwnProperty("published")
                    ? _c(
                        "div",
                        [
                          _c(
                            "BaseInput",
                            {
                              attrs: {
                                label: `${_vm.$t("active")}/${_vm.$t(
                                  "published"
                                )}`,
                                "is-slider-checkbox": "",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.published,
                                    expression: "item.published",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  type: "checkbox",
                                  "true-value": "1",
                                  "false-value": "0",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.item.published)
                                    ? _vm._i(_vm.item.published, null) > -1
                                    : _vm._q(_vm.item.published, "1"),
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.item.published,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? "1" : "0"
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.item,
                                            "published",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.item,
                                            "published",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.item, "published", $$c)
                                    }
                                  },
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._t("sidebar-middle"),
                  _vm.supportsPhoto
                    ? _c("div", { staticClass: "photo" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.item.library_id,
                              expression: "item.library_id",
                            },
                          ],
                          ref: "libraryModel",
                          attrs: { type: "hidden" },
                          domProps: { value: _vm.item.library_id },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.item,
                                "library_id",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _c("label", [_vm._v(_vm._s(_vm.$tc("photo")))]),
                        _vm.displayedPhoto
                          ? _c(
                              "div",
                              { staticClass: "photoContainer" },
                              [
                                _c("BaseImage", {
                                  attrs: {
                                    source: _vm.displayedPhoto,
                                    size: "thumb",
                                  },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "photoOverlay text-center" },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("BaseIcon", {
                                          staticClass:
                                            "icon iconHover iconEdit",
                                          attrs: {
                                            "icons-group": "far",
                                            icon: "fa-edit",
                                            size: "l",
                                          },
                                          on: { click: _vm.openLibrary },
                                        }),
                                        _c("div", [
                                          _vm._v(_vm._s(_vm.$t("edit"))),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      [
                                        _c("BaseIcon", {
                                          staticClass:
                                            "icon iconHover iconDelete",
                                          attrs: {
                                            "icons-group": "fas",
                                            icon: "fa-ban",
                                            size: "l",
                                          },
                                          on: { click: _vm.removePhoto },
                                        }),
                                        _c("div", [
                                          _vm._v(_vm._s(_vm.$t("remove"))),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", { staticClass: "photoContainer" }, [
                              _c("div", { staticClass: "photoOverlay" }, [
                                _c(
                                  "div",
                                  { staticClass: "text-center" },
                                  [
                                    _c("BaseIcon", {
                                      staticClass: "icon iconHover iconAdd",
                                      attrs: {
                                        "icons-group": "fas",
                                        icon: "fa-plus-circle",
                                        size: "xl",
                                      },
                                      on: { click: _vm.openLibrary },
                                    }),
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("addItem", {
                                            item: _vm.$tc("photo"),
                                          })
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "photoAlt" },
                                [
                                  _c("BaseIcon", {
                                    staticClass: "icon iconImage",
                                    attrs: {
                                      "icons-group": "far",
                                      icon: "fa-image",
                                      size: "xxxl",
                                      fill: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "sideBottom" },
                    [
                      _vm.item && "seo_title" in _vm.item
                        ? _c(
                            "div",
                            { staticClass: "metaTitle" },
                            [
                              _c("label", [
                                _vm._v(
                                  "SEO " +
                                    _vm._s(_vm.$t("title")) +
                                    " (" +
                                    _vm._s(_vm.$t("min")) +
                                    " 40) "
                                ),
                                _c(
                                  "span",
                                  {
                                    class: [
                                      _vm.validLength(
                                        40,
                                        70,
                                        _vm.item.seo_title
                                          ? _vm.item.seo_title.length
                                          : "0"
                                      ),
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.item.seo_title
                                          ? _vm.item.seo_title.length
                                          : "0"
                                      ) + "/70"
                                    ),
                                  ]
                                ),
                              ]),
                              _c("BaseInput", {
                                attrs: {
                                  placeholder: `${_vm.$t("enter")} SEO ${_vm.$t(
                                    "title"
                                  )} ${_vm.$t("here")}...`,
                                },
                                model: {
                                  value: _vm.item.seo_title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.item, "seo_title", $$v)
                                  },
                                  expression: "item.seo_title",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.item && "description_socials" in _vm.item
                        ? _c(
                            "div",
                            { staticClass: "metaDescription form-group" },
                            [
                              _c("label", [
                                _vm._v(
                                  "SEO " +
                                    _vm._s(_vm.$t("description")) +
                                    " (" +
                                    _vm._s(_vm.$t("min")) +
                                    " 50) "
                                ),
                                _c(
                                  "span",
                                  {
                                    class: [
                                      _vm.validLength(
                                        50,
                                        160,
                                        _vm.item.description_socials
                                          ? _vm.item.description_socials.length
                                          : "0"
                                      ),
                                    ],
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.item.description_socials
                                          ? _vm.item.description_socials.length
                                          : "0"
                                      ) + "/160"
                                    ),
                                  ]
                                ),
                              ]),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.item.description_socials,
                                    expression: "item.description_socials",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  cols: "30",
                                  rows: "10",
                                  placeholder: `${_vm.$t("enter")} SEO ${_vm.$t(
                                    "description"
                                  )} ${_vm.$t("here")}...`,
                                },
                                domProps: {
                                  value: _vm.item.description_socials,
                                },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.item,
                                      "description_socials",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._t("sidebar-bottom"),
                    ],
                    2
                  ),
                ],
                2
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "Modal",
        {
          attrs: {
            show: _vm.isLibraryModalVisible,
            centered: true,
            "show-close": true,
          },
          on: {
            "update:show": function ($event) {
              _vm.isLibraryModalVisible = $event
            },
          },
        },
        [
          _vm.isLibraryModalVisible
            ? _c("LibraryList", {
                staticClass: "library",
                attrs: {
                  limit: 40,
                  "select-items": "single",
                  "grid-item-size": "100",
                  "preselected-items": _vm.displayedPhoto
                    ? [_vm.displayedPhoto]
                    : [],
                  "fixed-type": "photos",
                },
                on: { updateSelected: _vm.updateMainPhoto },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "libraryButton text-right" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { type: "green", gradient: "" },
                  on: { click: _vm.confirmNewPhoto },
                },
                [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }