var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "label",
        _vm.float && "float",
        _vm.float && _vm.position,
        _vm.size,
        _vm.color,
        _vm.shape && _vm.shape,
      ],
      style: `${_vm.spacing && `margin: ${_vm.spacing}`}`,
    },
    [_c("span", { staticClass: "labelText" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }