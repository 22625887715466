var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("ul", { staticClass: "nav" }, [
        _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: { href: _vm.siteLink, target: "_blank" },
            },
            [_vm._v(" " + _vm._s(_vm.siteLink) + " ")]
          ),
        ]),
      ]),
      _c("div", { staticClass: "copyright" }, [
        _vm._v(
          " © " + _vm._s(_vm.year) + " " + _vm._s(_vm.$t("madeWith")) + " "
        ),
        _c("i", { staticClass: "tim-icons icon-heart-2" }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }