var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: {
        "input-group": _vm.hasIcon,
        "input-group-focus": _vm.focused,
      },
    },
    [
      _vm._t("label", function () {
        return [
          _vm.label
            ? _c("label", { staticClass: "control-label" }, [
                _vm._v(" " + _vm._s(_vm.label) + " "),
              ])
            : _vm._e(),
        ]
      }),
      _vm._t("addonLeft", function () {
        return [
          _vm.addonLeftIcon
            ? _c("span", { staticClass: "input-group-prepend" }, [
                _c("div", { staticClass: "input-group-text" }, [
                  _c("i", { class: _vm.addonLeftIcon }),
                ]),
              ])
            : _vm._e(),
        ]
      }),
      _vm.isSliderCheckbox
        ? _c("div", [
            _c(
              "label",
              { staticClass: "switch" },
              [
                _vm._t("default", function () {
                  return [
                    _c(
                      "input",
                      _vm._g(
                        _vm._b(
                          {
                            staticClass: "form-control",
                            attrs: {
                              type: "checkbox",
                              "aria-describedby": "addon-right addon-left",
                            },
                            domProps: { value: _vm.value },
                          },
                          "input",
                          _vm.$attrs,
                          false
                        ),
                        _vm.listeners
                      )
                    ),
                  ]
                }),
                _c("span", { staticClass: "slider round" }),
              ],
              2
            ),
          ])
        : _vm._t("default", function () {
            return [
              _c(
                "input",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "form-control",
                      attrs: {
                        "aria-describedby": "addon-right addon-left",
                        type: _vm.type,
                      },
                      domProps: { value: _vm.value },
                    },
                    "input",
                    _vm.$attrs,
                    false
                  ),
                  _vm.listeners
                )
              ),
            ]
          }),
      _vm._t("addonRight", function () {
        return [
          _vm.addonRightIcon
            ? _c("span", { staticClass: "input-group-append" }, [
                _c("div", { staticClass: "input-group-text" }, [
                  _c("i", { class: _vm.addonRightIcon }),
                ]),
              ])
            : _vm._e(),
        ]
      }),
      _vm._t("helperText"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }