var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "multiSelect" }, [
    _c("label", [_vm._v(_vm._s(_vm.label))]),
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.closeMenu,
            expression: "closeMenu",
          },
        ],
        staticClass: "multiSelectContainer form-control",
        on: { click: _vm.openMenu },
      },
      [
        _c(
          "div",
          { staticClass: "selectedContainer" },
          [
            _vm.selectedItems.length
              ? _c(
                  "div",
                  _vm._l(_vm.selectedItems, function (selected, i) {
                    return _c(
                      "div",
                      {
                        key: i + selected.id + selected.name,
                        staticClass: "selectedItem",
                      },
                      [
                        _c("div", { staticClass: "selectedItemInner" }, [
                          _c("span", [_vm._v(_vm._s(selected.name))]),
                          _c(
                            "span",
                            {
                              staticClass: "removeButton",
                              on: {
                                click: function ($event) {
                                  return _vm.removeItem(i)
                                },
                              },
                            },
                            [
                              _c("BaseIcon", {
                                attrs: {
                                  "icons-group": "fas",
                                  icon: "fa-times",
                                  size: "s",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "selectedAlternative" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("selectItem", { item: _vm.label })) +
                      " "
                  ),
                ]),
            _c("BaseIcon", {
              class: ["menuIcon", { menuIconOpened: _vm.isMenuOpened }],
              attrs: { "icons-group": "fas", icon: "fa-caret-down" },
            }),
          ],
          1
        ),
        _c(
          "ul",
          { class: ["menu", { opened: _vm.isMenuOpened }] },
          [
            _c("BaseInput", {
              attrs: { placeholder: _vm.$t("search") },
              nativeOn: {
                input: function ($event) {
                  return _vm.search.apply(null, arguments)
                },
              },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = $$v
                },
                expression: "keyword",
              },
            }),
            _vm._l(_vm.filteredItems, function (item, i) {
              return _c(
                "li",
                {
                  key: item + i + "multiselect",
                  class: [
                    "menuItem",
                    _vm.preSelectedItems &&
                    _vm.preSelectedItems.length &&
                    _vm.preSelectedItems.find((obj) => obj.id === item.id)
                      ? "active"
                      : "",
                  ],
                  on: {
                    click: function ($event) {
                      return _vm.addItem(item)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }