var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "btn",
      class: [
        { "btn-round": _vm.round },
        { "btn-block": _vm.block },
        { "btn-icon btn-fab": _vm.icon },
        { [`btn-${_vm.type}`]: _vm.type },
        { [`btn${_vm.type}`]: _vm.type },
        { btngradient: _vm.gradient },
        { [`btn-${_vm.size}`]: _vm.size },
        { "btn-simple": _vm.simple },
        { "btn-link": _vm.link },
        { disabled: _vm.disabled && _vm.tag !== "button" },
        { btnFill: _vm.fill },
      ],
      attrs: {
        type: _vm.tag === "button" ? _vm.nativeType : "",
        disabled: _vm.disabled,
      },
      on: { click: _vm.handleClick },
    },
    [_c("div", { staticClass: "buttonInner" }, [_vm._t("default")], 2)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }