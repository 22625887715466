var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "paginationContainer" },
    [
      _c("Pagination", {
        staticClass: "pagination listTablePagination",
        attrs: {
          page: _vm.offset,
          "total-items": _vm.totalItems,
          "items-per-page": _vm.limit,
          css: _vm.paginationCss,
        },
        on: { "on-update": _vm.updatePagination },
      }),
      _vm.totalDisplayed
        ? _c("div", { staticClass: "total" }, [
            _vm._v(" " + _vm._s(_vm.$t("displaying")) + " "),
            _c("span", { staticClass: "totalNumber" }, [
              _vm._v(_vm._s(_vm.totalDisplayed)),
            ]),
            _vm._v(" " + _vm._s(_vm.$t("of")) + " "),
            _c("span", { staticClass: "totalNumber" }, [
              _vm._v(_vm._s(_vm.totalItems)),
            ]),
            _vm._v(" " + _vm._s(_vm.$tc("item", 2)) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }