var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("side-bar", {
        attrs: { "background-color": "vue", "sidebar-links": _vm.sideBarLinks },
      }),
      _c(
        "div",
        { staticClass: "main-panel" },
        [
          _c("top-navbar"),
          _c("dashboard-content", {
            nativeOn: {
              click: function ($event) {
                return _vm.toggleSidebar.apply(null, arguments)
              },
            },
          }),
          _c("content-footer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }