var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vue-yoast vue-yoast-assessor vue-yoast-content-assessor" },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "div",
        { key: index, class: item._class },
        [
          _vm._t(
            "item",
            function () {
              return [
                _c("span", { staticClass: "vue-yoast-assessor-badge" }, [
                  _vm._v(" "),
                ]),
                _c("span", {
                  staticClass: "vue-yoast-assessor-text",
                  domProps: { innerHTML: _vm._s(item.text) },
                }),
              ]
            },
            { item: item }
          ),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }