var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AuthLayout",
    { attrs: { title: _vm.$tc("login") } },
    [
      _c(
        "form",
        {
          attrs: { name: "form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.handleLogin.apply(null, arguments)
            },
          },
        },
        [
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("emailOrUsername"),
              placeholder: _vm.$t("enterYourEmailOrUsername"),
              type: "text",
              autocomplete: "email",
            },
            model: {
              value: _vm.user.email,
              callback: function ($$v) {
                _vm.$set(_vm.user, "email", $$v)
              },
              expression: "user.email",
            },
          }),
          _c("BaseInput", {
            attrs: {
              label: _vm.$t("password"),
              placeholder: _vm.$t("enterYourPassword"),
              type: "password",
              autocomplete: "password",
            },
            model: {
              value: _vm.user.password,
              callback: function ($$v) {
                _vm.$set(_vm.user, "password", $$v)
              },
              expression: "user.password",
            },
          }),
          _c(
            "BaseButton",
            {
              staticClass: "button",
              attrs: {
                slot: "footer",
                type: "blue",
                gradient: "",
                "native-type": "submit",
                fill: "",
              },
              on: { click: () => {} },
              slot: "footer",
            },
            [_vm._v(" " + _vm._s(_vm.$tc("login")) + " ")]
          ),
        ],
        1
      ),
      _c("br"),
      _c("div", [_vm._v(_vm._s(_vm.$t("forgotYourPassword")))]),
      _c("router-link", { attrs: { to: { name: "forgotPassword" } } }, [
        _vm._v(" " + _vm._s(_vm.$t("clickHereToResetYourPassword")) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }