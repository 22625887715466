var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.background && _vm.source
    ? _c("div", {
        staticClass: "background",
        style: `background-image: url('${_vm.source[_vm.model][_vm.size]}')`,
      })
    : !_vm.background && _vm.source
    ? _c(
        "figure",
        {
          directives: [{ name: "lazyload", rawName: "v-lazyload" }],
          key: _vm.source.id,
          class: ["wrap", _vm.fill && "fill", _vm.fit && "fit"],
        },
        [
          _c("span", { staticClass: "spinner" }),
          _c("img", {
            staticClass: "item",
            attrs: {
              "data-url": _vm.isPdf
                ? _vm.pdfLogo
                : _vm.model && _vm.size
                ? _vm.source[_vm.model][_vm.size].URL
                : _vm.source,
              alt: _vm.model ? _vm.source[_vm.model].alt : _vm.alt,
              width:
                _vm.model && _vm.size
                  ? _vm.source[_vm.model][_vm.size].width
                  : "",
              height:
                _vm.model && _vm.size
                  ? _vm.source[_vm.model][_vm.size].height
                  : "",
            },
          }),
        ]
      )
    : _c(
        "div",
        { staticClass: "noImage" },
        [
          _c("BaseIcon", {
            staticClass: "noImageIcon",
            attrs: { icon: "image" },
          }),
          _c("BaseLabel", { staticClass: "label", attrs: { float: "" } }, [
            _vm._v(" " + _vm._s(_vm.customText || _vm.$t("noImage")) + " "),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }